<template>
  <div class="table-container" v-bind:class="classObject">
    <table class="table table-md-responsive" v-if="orderList.length > 0">
      <!-- v-if="showLoader == 0" -->

      <thead>
        <tr>
          <th>
            Order Number
          </th>
          <th class="text-center">Customer</th>
          <th class="text-center">Status</th>
          <!-- <th class="text-center">
            Total Price
          </th> -->
          <th class="text-center">
            Email
          </th>

          <th class="text-center">
            Shop Name
          </th>
          <th class="text-center">CO Risk Level</th>
          <th class="text-center">
            Created At
          </th>
          <th class="text-center">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in orderList" :key="index">
          <td>
            <div class="company-details">
              <h6 class="company-name">{{ item.order_number }}</h6>
            </div>
          </td>
          <td class="text-center" >{{ item?.customer?.first_name || item.Customer_id }} {{ item?.customer?.last_name }}</td>
          <td class="text-center text-capitalize">
            <span v-if="item.status">{{ item.status }}</span>
            <span v-else>
              <span v-if="item.approved === null">Pending</span>
              <span v-else-if="item.approved">Approved</span>
              <span v-else>Disapproved</span>
            </span>
          </td>
          <!-- <td class="text-center" >{{ item.total_price}}</td> -->
          <td class="text-center">{{ item.email }}</td>
          <td class="text-center">{{ item.shopName }}</td>
          <td class="text-center" v-if="item.suspicious_activities[0]">
            {{
              item.suspicious_activities[0].Risk_level.charAt(0).toUpperCase() +
                item.suspicious_activities[0].Risk_level.slice(1).toLowerCase()
            }}
          </td>
          <td class="text-center" v-else>-</td>
          <td class="text-center">
            {{ item.created_at | moment("MMM D, YYYY") }}
          </td>
          <td class="text-center">
            <div v-if="item.approved === null">
              <div class="dropdown">
                <button
                  class="btn btn-default"
                  type="button"
                  id="dropdownMenu1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  &#x2026;
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                  <li @click="verifyOrder(item)">Approve</li>
                  <li @click="unVerifyOrder(item)">Disapprove</li>
                </ul>
              </div>
            </div>
            <span v-else>
              N/A
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="showLoader == 0 && orderList.length == 0"
      class="h-75 d-flex flex-column justify-content-center"
    >
      <p class="no-registry">No orders found.</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OrderTable",

  data() {
    return {};
  },
  props: {
    orderList: {
      type: Array,
    },
    message: Boolean,
    showLoader: Number,
  },
  methods: {
    verifyOrder(item) {
      this.$swal({
        title: "Are you sure?",
        text: "Do you want to approve this order?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, approve it!",
        cancelButtonText: "No, cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          var formData = new FormData();
          formData.append("order_id", item.id);
          formData.append("item", JSON.stringify(item));
          formData.append("approved", true);

          axios
            .post(process.env.VUE_APP_API_URL + "approveOrder", formData)
            .then((response) => {
              const statusCode = response.data.statusCode || 500;
              if (statusCode === 200) {
                console.log("Order approved:", item.id);
                this.$root.$emit("update-data");

                this.$swal({
                  title: "Success!",
                  text: "Order has been approved successfully.",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log("Error occurred:", error);

              this.$swal({
                title: "Error!",
                text: "An error occurred while approving the order.",
                icon: "error",
              });
            });
        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          this.$swal({
            title: "Cancelled",
            text: "Order approval has been cancelled.",
            icon: "info",
          });
        }
      });
    },

    unVerifyOrder(item) {
      this.$swal({
        title: "Are you sure?",
        text: "Do you want to disapprove this order?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, disapprove it!",
        cancelButtonText: "No, cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          var formData = new FormData();
          formData.append("order_id", item.id);
          formData.append("approved", false);
          formData.append("item", JSON.stringify(item));

          axios
            .post(process.env.VUE_APP_API_URL + "approveOrder", formData)
            .then((response) => {
              const statusCode = response.data.statusCode || 500;
              if (statusCode === 200) {
                this.$root.$emit("update-data");
                console.log("Order disapproved:", item);

                this.$swal({
                  title: "Success!",
                  text: "Order has been disapproved successfully.",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log("Error occurred:", error);

              this.$swal({
                title: "Error!",
                text: "An error occurred while disapproving the order.",
                icon: "error",
              });
            });
        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          this.$swal({
            title: "Cancelled",
            text: "Order disapproval has been cancelled.",
            icon: "info",
          });
        }
      });
    },
  },
  computed: {
    classObject: function() {
      return {
        "reg-table": this.message,
        "reg2-table": !this.message,
      };
    },
  },

  mounted() {},
};
</script>
<style scoped>
#dropdownMenu1 {
  font-weight: 800;
}
#dropdownMenu1:focus {
  border: none !important;
  box-shadow: none !important;
}
.dropdown-menu {
  background-color: var(--white);
  border-radius: 15px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  top: -7px !important;
  left: -42px !important;
  min-width: 6rem;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.dropdown-menu li {
  padding-left: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  color: var(--main-black);
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: block;
}
.dropdown-menu li:hover {
  background-color: rgb(241, 240, 240);
}
</style>
