<template>
  <div class="registry-wrapper-top">
    <div class="heading">
      <h5>Orders</h5>
    </div>
    <div class="buttons">
      <div class="form-group mb-0 mx-2" @keyup.enter="search">
        <button class="search-icon" @click="search">
          <img src="../../assets/images/search.png" alt="Search" />
        </button>
        <input
          class="form-control"
          type="search"
          placeholder="Search Here..."
          aria-label="Search"
          v-model="filter.search"
          @keydown.enter="search"
          title="You can search by Order Number, Customer & by Email."
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderTop",
  props: {
    count: Number,
    searched: String,
  },
  data() {
    return {
      filter: {
        search: "",
      },
    };
  },
  watch: {
    'filter.search'(newVal) {
      if (newVal === "") {
        this.refreshListing(); // Refresh listing when the search input is cleared
      }
    },
  },
  methods: {
    search() {
      console.log(this.filter);
      this.$root.$emit("searchForOrders", this.filter);
    },
    refreshListing() {
      console.log("Listing refreshed"); // For demonstration purposes
      this.$root.$emit("searchForOrders", { search: "" }); // Emit an event to fetch all orders
    },
  },
};
</script>

<style>
/* Your styles here */
</style>
